import axios from 'axios';

class Api {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  getDaoInfo() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.apiUrl}/dao-info`)
        .then(({ data }) => resolve(data))
        .catch((e) => reject(e));
    });
  }
}

export default new Api();
